<template>
    <div class="big-box">
        <div class="nav" v-if="id == 1 || id == 2">
            <div v-if="id == 1">
                <img src="../../assets/images/login/ts.png" alt="" />
                正在注册【企业用户】
            </div>
            <div v-if="id == 2">
                <img src="../../assets/images/login/ts.png" alt="" />
                正在注册【个人用户】
            </div>
        </div>
        <div class="main">
            <div class="box">
                <div class="title" v-if="showContent">企胖胖平台服务协议、隐私权政策、法律声明如下：</div>
                <div v-html="textContent"></div>
            </div>
        </div>
        <div class="footer">
            <div class="foot">
                <div class="company">
                    <div class="official">
                        <div class="company-title">企胖胖 <span>企服电商先行者</span></div>
                        <div class="call">官方客服电话: <span>400-090-3278</span></div>
                    </div>
                    <div class="foot-menus">
                        <div class="li" v-for="item in menusList" :key="item.id">
                            {{ item.title }}
                            <div v-for="(val,index) in item.child" :key="val.id" @click="clickFun(item.id,index)">{{ val.title }}</div>
                        </div>
                    </div>
                </div>
                <div class="tail">
                    <!-- <div style="width:300px;margin:0 auto; padding:20px 0;">
                    <a target="_blank" href=" " style="display:inline-block;text-decoration:none;height:20px;line-height:20px;">
                        <img src="../assets/images/db028ed16afc399dcea4d05dddd0982.png" style="float:left;" />
                        <p style="float:left;height:20px;line-height:20px;margin: 0px 0px 0px 5px; color:#939393;">粤公网安备 44030302002183号</p>
                    </a>
                </div> -->
                    Copyright 2018-2022 qfpanda.com
                    <span>
                        <a target="_blank" href="https://beian.miit.gov.cn/#/Integrated/index" style="display:inline-block;text-decoration:none;height:20px;line-height:20px; color:#999 ;">
                            版权所有粤ICP备2022022835号
                            <img src="../../assets/images/db028ed16afc399dcea4d05dddd0982.png" style="float:left;" />
                            <p style="float:left;height:20px;line-height:20px;margin: 0px 0px 0px 5px; color:#999 ;">粤公网安备 44030302002183号</p>
                        </a>
                    </span> 富邦(深圳)企业服务网络科技有限公司
                    互联网违法和不良信息举报电话：400-090-3278 邮箱：qipp360@163.com 信用广东
                    国家企业信用信息公示系统(广东) 地址：广东省深圳市罗湖区南湖街道建设路2016号南方证券大厦B座8楼
                </div>
            </div>
            <div class="brand">
                <div class="cooperate">
                    <div><img src="../../assets/images/foot1.png" alt="" /></div>
                    <div><img src="../../assets/images/foot2.png" alt="" /></div>
                    <div><img src="../../assets/images/foot3.png" alt="" /></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Footer from "../../components/footer.vue";
export default {
    components: { Footer },
    // components: {},
    data() {
        return {
            type: "html_yszc",
            showContent: true,
            textContent: "",
            id: this.$route.query.id,
            linkList: [],
            postList: ["Cooperation_1", "Cooperation_2", "Cooperation_3"],
            menusList: [
                {
                    id: 0,
                    title: "关于我们",
                    child: [
                        { id: 4, title: "公司信息" },
                        { id: 5, title: "合作联系" },
                        { id: 6, title: "平台介绍" },
                    ],
                },
                {
                    id: 1,
                    title: "政策协议",
                    child: [
                        { id: 0, title: "隐私保护政策" },
                        { id: 1, title: "平台规则" },
                        { id: 2, title: "法律知识" },
                    ],
                },
                {
                    id: 2,
                    title: "友情链接",
                    child: [

                    ],
                },
            ],
        };
    },
    created() {
        window.scrollTo(0, 0);
    },
    mounted() {
        this.getFooterInfo()
        console.log(this.id);
        this.fundType();
        this.textContent = this.$route.query.textContent.replace(
            /<img [^>]*src=['"]([^'"]+)[^>]*>/g,
            (match, capture) => {
                return match.replace("../../", this.$util.host + "/");
            }
        );
    },

    methods: {
        fundType() {
            if (this.id == 1 || this.id == 2 || this.id == 3 || this.id == 0) {
                this.showContent = true;
                this.getText();
            } else {
                this.showContent = false;
                if (this.id == 4) {
                    //关于公司
                    this.type = "foot_1_about";
                } else if (this.id == 5) {
                    //合作联系
                    this.type = "foot_2_call";
                } else if (this.id == 6) {
                    this.type = "foot_3_introduce";
                    this.getText();
                }

                // location.reload();
            }
        },
        // getText() {
        //     this.$util
        //         .post("/sys-param/list", {
        //             paramKey: this.type,
        //         })
        //         .then((res) => {
        //             this.textContent = res.data[0].paramValue.replace(
        //                 /<img [^>]*src=['"]([^'"]+)[^>]*>/g,
        //                 (match, capture) => {
        //                     return match.replace("../../", this.$util.host + "/");
        //                 }
        //             );

        //         });
        // },
        clickFun(idOne, idTwo) {
            //友情链接
            if (idOne == 2) {
                window.location.href = this.menusList[2].child[idTwo].link
            } else if (idOne == 1) {
                this.type = "html_yszc"
                this.getText();
                // this.$router.push({ path: '/privacy', query: { id: 3 } })
            } else if (idOne == 0) {
                // this.$router.push({ path: '/privacy', query: { id: this.menusList[0].child[idTwo].id } })
                if (this.menusList[0].child[idTwo].id == 4) {
                    //关于公司
                    this.type = "foot_1_about";
                    this.getText();
                } else if (this.menusList[0].child[idTwo].id == 5) {
                    //合作联系
                    this.type = "foot_2_call";
                    this.getText();
                } else if (this.menusList[0].child[idTwo].id == 6) {
                    this.type = "foot_3_introduce";
                    this.getText();
                }


            }
        },
        getText() {
            this.$util
                .post("/sys-param/list", {
                    paramKey: this.type,
                })
                .then((res) => {
                    this.textContent = res.data[0].paramValue.replace(
                        /<img [^>]*src=['"]([^'"]+)[^>]*>/g,
                        (match, capture) => {
                            return match.replace("../../", this.$util.host + "/");
                        }
                    );
                    if (this.$route.query.id !== 3 || this.$route.query.id !== 6) {
                        this.$router.push({ path: '/privacy', query: { textContent: res.data[0].paramValue } })
                    }
					console.log(res,44564564)
                    window.scrollTo(0, 0);
                    console.log(res.data[0]);

                });
        },
        getFooterInfo() {
            var _this = this;
            for (var i in _this.postList) {
                var form = {
                    paramKey: _this.postList[i],
                };
                _this.$util.post("/sys-param/list", form).then((res) => {
                    //   _this.linkList.push(res.data[0]);
                    console.log(res);
                    if (_this.$route.query.id !== 3) {
                        console.log(111);
                        _this.menusList[2].child.push({ "title": res.data[0].paramValue, "link": res.data[0].paramMsg })
                    }
                });
            }
            console.log(_this.menusList)
        },
    },
};
</script>

<style lang="scss" scoped>
.big-box {
    background: #f6f6f6;
}
.nav {
    height: 50px;
    background: #e5f4fc;
    color: #2ea7e0;
    display: flex;
    align-items: center;
    div {
        display: flex;
        align-items: center;
        width: 1200px;
        font-size: 16px;
        margin: 0 auto;
        img {
            width: 25px;
            height: 25px;
            margin-right: 10px;
        }
    }
}
.main {
    background: #f6f6f6;
    .box {
        width: 1200px;
        // height: 100vh;
        background: #fff;
        margin: 30px auto 0;
        padding: 30px;
        .title {
            padding-bottom: 30px;
            border-bottom: 1px solid #dbdbdb;
            font-size: 22px;
            color: #000;
        }
    }
}
.footer {
    margin-top: 120px;
    // margin-top: 20px;
    // position: relative;
    // bottom: 0;
    // left: 0;
    // right: 0;
    // z-index: 5;
}
.foot {
    // position: absolute;
    // bottom: 0;
    // left: 0;
    background: #171717;
}
.company {
    width: 1200px;
    margin: 0 auto 0;
    padding: 45px 0 50px;
    display: flex;
    justify-content: space-between;
    // align-items: center;
    .official {
        height: 144px;
        padding-right: 120px;
        border-right: 1px solid #4a4a4a;
        .company-title {
            font-size: 30px;
            color: #fff;
            margin-bottom: 20px;
            padding-top: 19px;
            span {
                font-size: 18px;
                color: #999999;
            }
        }
    }
    .call {
        font-size: 14px;
        color: #999;
        span {
            color: #e40012;
        }
    }
}
.foot-menus {
    display: flex;
    justify-content: space-between;
    flex: 1;
    margin-left: 180px;
    .li {
        cursor: pointer;
        font-size: 16px;
        color: #fff;
        div {
            font-size: 14px;
            color: #999999;
            margin-top: 15px;
        }
    }
}
.tail {
    width: 1200px;
    margin: 0 auto;
    color: #999;
    font-size: 14px;
}
.brand {
    height: 125px;
    background: url("../../assets/images/footer-bg.png") round;
    background-color: #171717;
    padding: 10px 0 0;
}
.cooperate {
    width: 1200px;
    display: flex;
    align-items: center;
    margin: 0 auto;
    div {
        width: 100px;
        height: 38px;
        margin-right: 15px;
        img {
            width: 100%;
            height: 100%;
        }
    }
}
</style>
